body {
  font-size: 1em;
  font-family: 'OpenSansRegular', Helvetica, Arial, sans-serif;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  margin: 0px;
  min-height: 100vh;
  background-color: #f5f5f5;
}

button {
  border-radius: 5px;
  padding: 13px;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16),0 3px 12px 0 rgba(0,0,0,0.1);
  cursor: pointer;
  background: #333;
  box-sizing: border-box;
  font-size: 1em;
  font-family: 'OpenSansRegular', Helvetica, Arial, sans-serif;
  color: white;
}

select {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #CCCCCC;
  border-radius: 4px !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #555555;
  display: block;
  font-size: 0.875em;
  line-height: 1.42857;
  padding: 6px 12px;
      padding-top: 6px;
      padding-bottom: 6px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 50%;
  height: 2.5em !important;
  padding-bottom: 0;
  padding-top: 0;
}

.headerBar {
  background-color: #333;
  overflow: hidden;
  height: 70px;
  margin-bottom: 5px;
  font-family: Helvetica, sans-serif;  
}

/* Style the links inside the navigation bar */
.headerBar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 21px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.headerBar a:hover {
  background-color: #ddd;
  color: black;
}

/* Add an active class to highlight the current page */
.headerBar a.active {
  background-color: #04AA6D;
  color: white;
}

.logoGene {
  display: inline;
  float: left;
  margin: 15px;
  max-height: 40px;
}

.textGene {
  display: inline;
  float: left;
  margin-right: 40px;
  max-height: 40px;
  max-width: 100px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: bold;
  color: white;
}

.logoSoc {
  display: inline;
  float: left;
  margin: 15px;
  max-height: 40px;
}

.content {
  display: flex; /* or inline-flex */
  flex-direction: row;
}

.call-content {
  display: flex;
  flex-direction: column;
  order: 2;
}

.ccp-container {
  padding: 5px;
}

#custom-ccp {
  order: 2;
  height: 530px;
  width: 350px; 
}

#call-info {
  order: 1;
  margin: 15px;
}

#call-classifier {
  order: 1;
  margin: 15px;
}

.hidden {
  visibility: collapse;
}

.call-classifier ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.call-classifier form li + li {
  margin-top: 1em;
}

.call-classifier-submit button:disabled,
  button[disabled] {
  color:grey;
  background-color: #a6a3a3;
  cursor: default;
}

#date {
  font-style: italic;
  font-size: 13px;
}

.footer {
  height: 25px;
  bottom: 0px;
  margin-top: auto;
  text-align: center;
  font-size: 12px;
  /* position: fixed; */
}