body {
  min-height: 100vh;
  background-color: #f5f5f5;
  flex-direction: column;
  margin: 0;
  font-family: OpenSansRegular, Helvetica, Arial, sans-serif;
  font-size: 1em;
  display: flex;
}

button {
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  background: #333;
  border-radius: 5px;
  padding: 13px;
  font-family: OpenSansRegular, Helvetica, Arial, sans-serif;
  font-size: 1em;
  box-shadow: 0 2px 3px #00000029, 0 3px 12px #0000001a;
}

select {
  color: #555;
  width: 50%;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0 12px;
  font-size: .875em;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #00000013;
  height: 2.5em !important;
  border-radius: 4px !important;
}

.headerBar {
  height: 70px;
  background-color: #333;
  margin-bottom: 5px;
  font-family: Helvetica, sans-serif;
  overflow: hidden;
}

.headerBar a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 21px 16px;
  font-size: 17px;
  text-decoration: none;
  display: block;
}

.headerBar a:hover {
  color: #000;
  background-color: #ddd;
}

.headerBar a.active {
  color: #fff;
  background-color: #04aa6d;
}

.logoGene {
  float: left;
  max-height: 40px;
  margin: 15px;
  display: inline;
}

.textGene {
  float: left;
  max-height: 40px;
  max-width: 100px;
  color: #fff;
  margin-right: 40px;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-weight: bold;
  display: inline;
}

.logoSoc {
  float: left;
  max-height: 40px;
  margin: 15px;
  display: inline;
}

.content {
  flex-direction: row;
  display: flex;
}

.call-content {
  flex-direction: column;
  order: 2;
  display: flex;
}

.ccp-container {
  padding: 5px;
}

#custom-ccp {
  height: 530px;
  width: 350px;
  order: 2;
}

#call-info, #call-classifier {
  order: 1;
  margin: 15px;
}

.hidden {
  visibility: collapse;
}

.call-classifier ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.call-classifier form li + li {
  margin-top: 1em;
}

.call-classifier-submit button:disabled, button[disabled] {
  color: gray;
  cursor: default;
  background-color: #a6a3a3;
}

#date {
  font-size: 13px;
  font-style: italic;
}

.footer {
  height: 25px;
  text-align: center;
  margin-top: auto;
  font-size: 12px;
  bottom: 0;
}

/*# sourceMappingURL=index.ee1cbf2c.css.map */
